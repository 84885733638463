import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading, H2, Reviews } from '../../components';

import css from './ListingPage.module.css';

const SectionReviews = props => {
  const { reviews, fetchReviewsError } = props;
  const [sortOption, setSortOption] = useState('newest');

  const sortReviews = (reviews, option) => {
    switch (option) {
      case 'newest':
        return [...reviews].sort((a, b) => new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt));
      case 'oldest':
        return [...reviews].sort((a, b) => new Date(a.attributes.createdAt) - new Date(b.attributes.createdAt));
      case 'highest':
        return [...reviews].sort((a, b) => b.attributes.rating - a.attributes.rating);
      case 'lowest':
        return [...reviews].sort((a, b) => a.attributes.rating - b.attributes.rating);
      default:
        return reviews;
    }
  };
  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };

  const sortedReviews = sortReviews(reviews, sortOption);
  return (
    <section className={css.sectionReviews}>
      <Heading as="h2" rootClassName={css.sectionHeadingWithExtraMargin}>
        <FormattedMessage id="ListingPage.reviewsTitle" values={{ count: sortedReviews.length }} />
        {
          reviews && reviews.length > 1 &&
          <p className={css.sortedOption}>
            <span>
              Sort by:
            </span>
            <select value={sortOption} onChange={handleSortChange} className={css.sortSelect}>
              <option value="newest">Newest</option>
              <option value="oldest">Oldest</option>
              <option value="highest">Highest Rating</option>
              <option value="lowest">Lowest Rating</option>
            </select>
          </p>
        }
      </Heading>
      {fetchReviewsError ? (
        <H2 className={css.errorText}>
          <FormattedMessage id="ListingPage.reviewsError" />
        </H2>
      ) : null}
      <Reviews reviews={sortedReviews} />
    </section >
  );
};

export default SectionReviews;
